.feature-callout {
    position: relative;
    clear: both;

    &.feature-content-left {
        .callout-feature-content {
            @media (min-width: $screen-sm-min) { padding-right: 2em; }
            @media (min-width: $screen-lg-min) { padding-right: 3em; }
        }
    }
    &.feature-content-right {
        .callout-feature-content {
            @media (min-width: $screen-sm-min) { padding-left: 2em; }
            @media (min-width: $screen-lg-min) { padding-left: 3em; }
        }
    }

    &.feature-content-center {
        .callout-feature-content {
            text-align: center;
            max-width: 75%;
            margin: 0 auto;
        }
    }

    .md-button {
        padding: 5px 0;
        min-width: 150px;
    }
}

.feature-callout-cover,
.feature-callout-image-pull {
    background-size: cover;
    background-position: center;
}

.feature-callout-image-pull {
    min-height: 200px;
    @media (min-width: $screen-sm-min) {
        position:absolute;
        top: 0;
        bottom: 0
    }
}

.callout-feature-content {
    padding: 3em 0;
    @media (min-width: $screen-sm-min) {
        padding-top: 6em;
        padding-bottom: 6em;
    }
    @media (min-width: 992px) {
        padding-top: 8em;
        padding-bottom: 8em;
    }
    @media (min-width: $screen-lg-min) {
        padding-top: 12em;
        padding-bottom: 12em;
    }

    h2 {
        font-size: 24px;
        margin-top: 0;
        @media (min-width: $screen-sm-min) {
            font-size: 30px;
            font-weight: 400;
            line-height: 1.9;
            margin: 0;
        }
    }
    p {
        line-height: 1.75;
        hyphens: none;
        &:last-child {
            margin-bottom: 0
        }
        @media (min-width: $screen-sm-min) {
            line-height: 2;
            margin: 1.5em 0;
        }
    }
}

.feature-callout-cover {
    &.has-overlay{
        color: $white;

        &:after {
            content: ' ';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            background: rgba(0,0,0,.25);
        }
    }

    .with-maxwidth {
        position: relative;
        z-index: 2;
    }

    h2 {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: .1em;
        @media (min-width: $screen-sm-min) {
            font-size: 48px;
        }
    }
}
