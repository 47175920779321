.call-to-action {
    position: relative;
    text-align: center;
    .cta-inner {
        padding: 2em 0;
    }
    .cta-text {
        font-size: 24px;
        line-height: 30px;
    }
    .cta-btn {
        margin: 30px 0 10px;
    }
    .cta-muted {
        opacity: .7;
    }
    &.cta-inline {
        .cta-text {
            font-size: 20px;
            // line-height: 30px;
            font-weight: $font-weight-thin;
        }

        .cta-btn {
            display: inline-block;
            margin: 0 0 0 30px;
        }
    }

    &.cta-full-width {
        .cta-inner {
            padding: 6em 1em;
        }
    }

    &.cta-bg-img {
        background-size: cover;
        background-position: center;
        color: rgba(#fff, .8);
        .cta-inner {
            background-color: rgba(#000, .35);
            padding: 7em 1em;
        }
    }
}

