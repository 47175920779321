.pricing-table {
    position: relative;
    margin-bottom: 20px;
    background-color: #fcfcfc;
    color: #999;
    border-radius: $border-radius-large;
    box-shadow: none;
    text-align: center;
    @include transition(.25s ease-out);

    &:hover {
        box-shadow: 0 27px 35px 23px rgba(0, 0, 0, 0.35), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
    }

    header {
        background-color: darken($gray-light2, 5%);
        text-align: center;
        padding: $padding-base-horizontal $padding-base-vertical;
        h2 {
            margin: 0;
            font-size: 20px;
            line-height: 1.3;
            font-weight: normal;
            text-transform: uppercase;
            color: $white;
        }
    }

    .pricing-price {
        padding: 45px 15px;
        background-color: $gray-light2;
        font-size: 56px;
        line-height: 1;
        color: #fff;
        font-weight: $font-weight-thin;
        .pricing-sign {
            margin-right: 5px;
            font-size: 24px;
            font-weight: normal;
        }
        .pricing-sub {
            margin-left: 5px;
            font-size: 22px;
            line-height: 1;
        }
    }

    .pricing-lead {
        margin-bottom: 1.5em;
        font-size: 18px;
        text-transform: uppercase;
        &:after {
            margin: 0 auto;
            content: "";
            height: 0px;
            width: 80px;
            padding-top: .6em;
            border-bottom: solid 3px #eff0f3;
            display: block;
        }
    }

    .pricing-plan-details {
        padding: 1em;
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: $font-size-base;
            line-height: 2em;
        }
    }

    > footer {
        padding: 2em;
        text-align: center;
        > a {
            display: block;
            margin: 0 auto;
            padding: 12px;
            font-size: 14px;
            line-height: 1;
            width: 100%;
            max-width: 200px;
        }
    }

    // color
    &.pricing-table-primary {
        header {
            background-color: darken($brand-primary, 5%);
        }
        .pricing-price {
            background-color: $brand-primary;
        }
        &:after {
            border-top: 6px solid $brand-primary;
            border-left: 6px solid $brand-primary;
        }
    }
     &.pricing-table-secondary,
    &.pricing-table-info {
        header {
            background-color: darken($brand-info, 5%);
        }
        .pricing-price {
            background-color: $brand-info;
        }
        &:after {
            border-top: 6px solid $brand-info;
            border-left: 6px solid $brand-info;
        }
    }
    &.pricing-table-success {
        header {
            background-color: darken($brand-success, 5%);
        }
        .pricing-price {
            background-color: $brand-success;
        }
        &:after {
            border-top: 6px solid $brand-success;
            border-left: 6px solid $brand-success;
        }
    }
    &.pricing-table-warning {
        header {
            background-color: darken($brand-warning, 5%);
        }
        .pricing-price {
            background-color: $brand-warning;
        }
        &:after {
            border-top: 6px solid $brand-warning;
            border-left: 6px solid $brand-warning;
        }
    }
    &.pricing-table-danger {
        header {
            background-color: darken($brand-danger, 5%);
        }
        .pricing-price {
            background-color: $brand-danger;
        }
        &:after {
            border-top: 6px solid $brand-danger;
            border-left: 6px solid $brand-danger;
        }
    }

}

// Dark theme
// --------------------------------------------------
.theme-gray {
    .pricing-table {
        background-color: $theme_gray_box_bg;
    }
}
.theme-dark {
    .pricing-table {
        background-color: $theme_dark_box_bg;
    }
}
