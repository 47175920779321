.card-white {
    color: $text-color;
    background-color: #fff;
}



// 
a.item-card {
    display: block;
    text-decoration: none;
    &:hover {
        box-shadow: 0 0 15px rgba(0,0,0, .1);
    }
}
.item-card {
    position: relative;
    transition: 0.2s linear;

    img {
        max-width: 100%;
        max-height: 100%;
    }
    .card__image {
        padding: 30px 15px;
        height: 300px;
        background-color: rgba(#000, .035);
        text-align: center;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        color: $text-color;
        line-height: $line-height-computed;
    }
    h6 {
        font-size: 10px;
        opacity: .5;
    }
    h4 {
        font-size: 16px;
    }

    .card__body {
        position: relative;
        padding: 30px 15px;
        margin-bottom: 30px;
    }

    .card__price {
        position: absolute;
        top: 30px;
        right: 15px;
        span {
            display: block;
            color: $gray-light;
            &.type--strikethrough {
                opacity: .5;
                text-decoration: line-through;
            }
        }
    }

    &.card__horizontal {
        position: relative;
        margin-bottom: 30px;

        .card__image {
            width: 50%;
        }

        .card__body {
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            padding: 40px 30px;
        }
        .card__price {
            top: 30px;
            right: 30px;
        }

        .card__desc {
            margin: 0 25% 3em 0;
            opacity: .7;
        }
    }
}


// Dark theme
.theme-gray,
.theme-dark {
    .card-white {
        color: $theme_dark_text_color;
        h1, h2, h3, h4, h5, h6 {
            color: $theme_dark_text_color;
        }
    }
}
.theme-gray {
    .card-white {
        background-color: $theme_gray_box_bg;
    }
}
.theme-dark {
    .card-white {
        background-color: $theme_dark_box_bg;
    }
}
